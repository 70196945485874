import { getTranslatedProperty } from '@shopware-pwa/helpers-next'
import type { Schemas } from '#shopware'

export type ShopwareGlobals = {
  salutations: Schemas['Salutation'][]
  countries: Schemas['Country'][]
}

export const ShopGlobalsKey: InjectionKey<Ref<ShopwareGlobals | null>> =
  Symbol('ShopNavigation')

export const useInjectableShopGlobals = async () => {
  const { data } = await useAsyncData('useShopwareGlobals', async () => {
    const { fetchCountries, getCountries } = useCountries()
    const { fetchSalutations, getSalutations } = useSalutations()

    await Promise.all([fetchCountries(), fetchSalutations()])

    return {
      salutations: getSalutations.value,
      countries: getCountries.value.sort((a, b) =>
        getTranslatedProperty(a, 'name').localeCompare(
          getTranslatedProperty(b, 'name')
        )
      ),
    }
  })

  return data
}
