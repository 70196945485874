<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.414 13.414">
    <path
      d="M18,6,6,18M6,6,18,18"
      transform="translate(-5.293 -5.293)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
  </svg>
</template>
