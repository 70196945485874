<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.155 60">
    <g transform="translate(34.078 29)">
      <g transform="translate(-34.078 -29)">
        <path
          d="M29.1,3.36c0-2.041-.915-1.945-2.947-2.184v-.5L31.661,0V16.1c0,2.419,0,2.593,2.422,2.593H36.7c2.214,0,2.3-1.236,2.536-3.215h.915l-.146,4.367H29.1Z"
          transform="translate(-11.824)"
          fill="currentColor"
        />
        <path
          d="M60.841,18.468a5.25,5.25,0,0,1-4.192,2.214A3.34,3.34,0,0,1,53.118,17.2c0-4.215,5.81-4.328,7.723-4.389V11.653c0-2.063,0-4.184-2.476-4.184-2.241,0-2.389,1.829-2.447,2.6H53.294c.116-1.741,1.268-3.75,5.158-3.75,3.95,0,4.662,2.092,4.662,4.812v9.23H60.841ZM54.472,2.629a1.513,1.513,0,0,1,1.5-1.5,1.537,1.537,0,0,1,1.534,1.5,1.531,1.531,0,0,1-1.534,1.5,1.51,1.51,0,0,1-1.5-1.5m6.37,11.1c-1.238.025-5.218.208-5.218,3.094a2.242,2.242,0,0,0,2.241,2.419c2.006,0,2.977-2.308,2.977-3.777Zm-1.174-11.1a1.516,1.516,0,1,1,1.531,1.5,1.517,1.517,0,0,1-1.531-1.5"
          transform="translate(-24.018 -0.51)"
          fill="currentColor"
        />
        <path
          d="M75.011,13.76c0-1.772-1.393-1.858-2.237-1.947v-.508l4.51-.705v3.923h.062c.437-1.148,1.97-3.923,4.922-3.923,2.865,0,3.507,1.447,3.507,3.543v10.5H83.505V15.113c0-1.592,0-3.066-2.065-3.066-2.15,0-4.156,3.625-4.156,5.575v7.02H75.011Z"
          transform="translate(-32.905 -4.793)"
          fill="currentColor"
        />
        <path
          d="M110.892,25.025c0,3.061-.942,5.633-5.778,5.633-4.221,0-5.368-1.738-5.427-3.538H102.4c.208,1.145.677,2.389,3.16,2.389,3.062,0,3.062-3.041,3.062-4.867V22.458h-.056a4.061,4.061,0,0,1-3.69,2.184c-3.629,0-5.452-3.011-5.452-6.933,0-3.949,1.5-7.109,5.54-7.109a4.011,4.011,0,0,1,3.6,2.213h.056V10.927h4.489v.5c-.772.088-2.218.146-2.218,1.974Zm-2.271-7.874c0-1.923-.386-5.4-3.394-5.4-2.768,0-3.3,3.66-3.3,6.166,0,2.559.647,5.572,3.538,5.572,2.124,0,3.156-2.62,3.156-4.9Z"
          transform="translate(-44.955 -4.793)"
          fill="currentColor"
        />
        <path
          d="M14.956,47.909a27.471,27.471,0,0,1-6.2.857C2.714,48.767,0,44.693,0,37.734c0-5.631,1.827-9.467,8.024-9.467,4.423,0,6.576,1.977,6.748,4.425H11.889c-.15-1.152-.827-3.1-3.921-3.1-4.6,0-5.075,4.69-5.075,7.9,0,4.956,1.564,9.935,7.225,9.935a7.717,7.717,0,0,0,2.27-.265V40.889c0-2.329-.411-2.567-2.98-2.98v-.5h5.547Z"
          transform="translate(0 -12.781)"
          fill="currentColor"
        />
        <path
          d="M38.666,51.615a5.261,5.261,0,0,1-4.186,2.213,3.347,3.347,0,0,1-3.542-3.48c0-4.223,5.808-4.342,7.728-4.4V44.8c0-2.066,0-4.188-2.476-4.188-2.247,0-2.387,1.83-2.452,2.6H31.117c.114-1.748,1.271-3.753,5.162-3.753,3.953,0,4.66,2.1,4.66,4.806v9.238H38.666Zm0-4.749c-1.239.029-5.222.207-5.222,3.1a2.246,2.246,0,0,0,2.243,2.419c2.009,0,2.979-2.3,2.979-3.776Z"
          transform="translate(-13.989 -17.843)"
          fill="currentColor"
        />
        <path
          d="M60.435,41.735a7.837,7.837,0,0,0-3.56-1.12c-1.275,0-2.452.471-2.452,1.888,0,2.949,6.928,2.477,6.928,7.1,0,2.951-2.237,4.221-5.249,4.221a11.372,11.372,0,0,1-3.8-.591V51.38a8.7,8.7,0,0,0,3.923,1.295c1.763,0,3.066-.65,3.066-2.5,0-3.011-6.933-2.948-6.933-6.849,0-2.265,1.359-3.862,4.809-3.862a13.424,13.424,0,0,1,3.272.5Z"
          transform="translate(-23.647 -17.843)"
          fill="currentColor"
        />
        <path
          d="M78.743,41.735a7.888,7.888,0,0,0-3.574-1.12c-1.264,0-2.442.471-2.442,1.888,0,2.949,6.926,2.477,6.926,7.1,0,2.951-2.24,4.221-5.245,4.221a11.368,11.368,0,0,1-3.8-.591V51.38a8.7,8.7,0,0,0,3.923,1.295c1.766,0,3.067-.65,3.067-2.5,0-3.011-6.936-2.948-6.936-6.849,0-2.265,1.362-3.862,4.811-3.862a13.514,13.514,0,0,1,3.275.5Z"
          transform="translate(-31.924 -17.843)"
          fill="currentColor"
        />
        <path
          d="M29.938,70.2H27.284c-2.481,0-2.534,1.066-2.713,2.861h-.915l.147-4.01H38.639l.146,4.01H37.87C37.7,71.267,37.7,70.2,35.161,70.2H32.506V88.9H29.938Z"
          transform="translate(-10.696 -31.222)"
          fill="currentColor"
        />
        <path
          d="M56.994,93.488a11.576,11.576,0,0,1-3.658.532c-4.337,0-6.873-2.678-6.873-7.6,0-4.247,1.624-6.754,5.932-6.754,2.772,0,4.954,1.624,4.954,5.134v.435H48.733v.622c0,2.862,2.069,7.022,6.169,7.022a8.194,8.194,0,0,0,2.092-.353Zm-2.212-9.525a2.884,2.884,0,0,0-2.773-3.153c-2.686,0-3.153,2.421-3.245,3.421h6.018Z"
          transform="translate(-21.009 -36.02)"
          fill="currentColor"
        />
        <path
          d="M80.069,93.488a11.609,11.609,0,0,1-3.655.532c-4.339,0-6.875-2.678-6.875-7.6,0-4.247,1.619-6.754,5.928-6.754,2.772,0,4.96,1.624,4.96,5.134v.435H71.81v.622c0,2.862,2.066,7.022,6.164,7.022a8.261,8.261,0,0,0,2.095-.353Zm-2.21-9.525a2.886,2.886,0,0,0-2.775-3.153c-2.685,0-3.154,2.421-3.243,3.421h6.017Z"
          transform="translate(-31.443 -36.02)"
          fill="currentColor"
        />
        <path
          d="M93.306,76.046a2.6,2.6,0,1,1-.014,0Zm-.032.5a2.01,2.01,0,0,0,.032,4.018,1.917,1.917,0,0,0,1.914-2.009,1.937,1.937,0,0,0-1.928-2.009Zm-.386,3.315H92.3v-2.5a5.264,5.264,0,0,1,.972-.09,1.417,1.417,0,0,1,.874.2.693.693,0,0,1,.243.537.648.648,0,0,1-.519.6v.035c.245.073.379.273.458.612a1.785,1.785,0,0,0,.183.615h-.626a1.912,1.912,0,0,1-.2-.6c-.049-.271-.2-.4-.525-.4h-.277Zm.018-1.414h.276c.323,0,.583-.105.583-.368,0-.228-.171-.385-.539-.385a1.827,1.827,0,0,0-.32.031Z"
          transform="translate(-41.01 -34.385)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>
