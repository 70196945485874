<template>
  <span
    class="cursor-pointer truncate font-agrandir-wide text-lg"
    :aria-label="$t('checkout.productLink', { name: cartItem.label })"
    @click="navigateToProduct(cartItem.referencedId as string)"
  >
    <slot />
  </span>
</template>

<script setup lang="ts">
import { getProductRoute } from '@shopware-pwa/helpers-next'
import type { Schemas } from '#shopware'
import { useNavigationSearch } from '#imports'

const props = defineProps<{
  cartItem: Schemas['LineItem'] | Schemas['OrderLineItem']
}>()

const { resolvePath } = useNavigationSearch()
const { getShopRoute } = useShopRouter()
const router = useRouter()

const navigateToProduct = async (productId: string) => {
  if (props.cartItem.type !== 'product' || !props.cartItem.referencedId) {
    return
  }
  const route = getProductRoute({
    id: props.cartItem.referencedId as string,
  })
  const seoUrl = await resolvePath(route.path)
  if (seoUrl) {
    const seoRoute = getProductRoute({
      id: productId,
      seoUrls: [seoUrl],
    })
    await router.push(getShopRoute(seoRoute.path))
  } else {
    await router.push(getShopRoute(route.path))
  }
}
</script>
