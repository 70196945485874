<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
  <div
    ref="element"
    :class="{
      bard: styled,
      'bard-sm': size === 'sm',
      'bard-md': size === 'md',
      'bard-text-lined': textUnderline,
    }"
    v-html="html ?? ''"
  />
</template>

<script setup lang="ts">
const element = ref<HTMLDivElement>()

withDefaults(
  defineProps<{
    html: string | null | undefined
    styled?: boolean
    size?: 'sm' | 'md'
    textUnderline?: boolean
  }>(),
  {
    styled: true,
    size: 'md',
    textUnderline: false,
  }
)

useNuxtLinks(element)
</script>
