import { defineRule, configure } from 'vee-validate'
import { required, email, min, confirmed } from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'
import de from './veeValidate-i18n/de.json'
import fr from './veeValidate-i18n/fr.json'
import en from './veeValidate-i18n/en.json'

export default defineNuxtPlugin((app) => {
  defineRule('required', required)
  defineRule('email', email)
  defineRule('min', min)
  defineRule('confirmed', confirmed)
  // to handle the nullable fields rules from statamic

  defineRule('nullable', () => {
    return true
  })

  configure({
    // controls if `blur` events should trigger validation with `handleChange` handler
    validateOnBlur: true,
    // controls if `change` events should trigger validation with `handleChange` handler
    validateOnChange: true,
    // controls if `input` events should trigger validation with `handleChange` handler
    validateOnInput: true,
    // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    validateOnModelUpdate: true,

    generateMessage: localize({
      de,
      fr,
      en,
    }),
  })

  // @ts-ignore
  setLocale(app.$i18n.locale.value)

  // @ts-ignore set correct locale on language switched
  app.$i18n.onLanguageSwitched = (_: string, newLocale: string) => {
    setLocale(newLocale)
  }
})
