import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["ContactForm","Events","NavigationsAndGlobals","News","Pages","TeaPreparationMethods","TeamMembers","Asset","ComponentsContactForm","ComponentsEvents","ComponentsGalleryWithInfo","ComponentsGalleryWithTiles","ComponentsHeaderText","ComponentsImages","ComponentsImageWithText","ComponentsLatestNews","ComponentsLinks","ComponentsLinksOverlayed","ComponentsLinksShopware","ComponentsMapHeader","ComponentsProductSlider","ComponentsSectionHeader","ComponentsTeam","ComponentsText","ComponentsTextWithSidebar","ComponentsVideo","ComponentsZigZag","Components","BlueprintEvent","BlueprintNews","BlueprintPage","Blueprints","Page"]}
export const GqlContactForm = (...params) => useGql()('ContactForm', ...params)
export const GqlEvents = (...params) => useGql()('Events', ...params)
export const GqlNavigationsAndGlobals = (...params) => useGql()('NavigationsAndGlobals', ...params)
export const GqlNews = (...params) => useGql()('News', ...params)
export const GqlPage = (...params) => useGql()('Page', ...params)
export const GqlPages = (...params) => useGql()('Pages', ...params)
export const GqlTeaPreparationMethods = (...params) => useGql()('TeaPreparationMethods', ...params)
export const GqlTeamMembers = (...params) => useGql()('TeamMembers', ...params)