import type { PageQuery } from '#gql'
import type { RouteLocationNormalizedLoaded } from '#vue-router'

export const EntryInjectionKey: InjectionKey<Ref<PageQuery['entry']>> =
  Symbol('Entry')

class FetchError extends Error {
  constructor(
    message: string,
    public status: number,
    public cause?: Error
  ) {
    super(message)
    Object.setPrototypeOf(this, FetchError.prototype)
  }
}

export const useInjectableEntry = async () => {
  const { locale, locales } = useI18n()
  const route = useRoute()

  const fetchRoute = ref(toValue(route))
  const isInLivePreview = computed(
    () => !!fetchRoute.value.query['live-preview']
  )

  const localeRegex = new RegExp(
    `/(${locales.value.map((l) => (typeof l === 'string' ? l : l.code)).join('|')})(?=/|$)`
  )
  const uri = computed(() => {
    // remove locale prefix from current route path
    let tmp = fetchRoute.value.path.replace(localeRegex, '')
    // remove trailing slash
    tmp = tmp.replace(/\/$/, '')
    // set uri to root if empty
    tmp = tmp === '' ? '/' : tmp

    return tmp
  })

  /**
   * If in live preview, reset GqlHost. This should be done by nuxt-base, but is only done on process.client
   * Should be removed once nuxt-base is updated to do this for us.
   */
  if (route.query.token) {
    // Add query parameters to the preconfigured graphql host
    useGqlHost(`?token=${route.query.token}`)
  }

  /**
   * Fetching during the initial mounting of the app. Here we use useAsyncGql to ensure
   * that the Gql query is only performed once during SSR and then passed to the client.
   */
  const { data, error } = await useAsyncGql('Page', {
    uri: uri.value,
    site: locale.value,
    status: isInLivePreview.value
      ? '(draft|expired|scheduled|published)'
      : 'published',
  })

  if (error.value) {
    console.error('Could not fetch page (initial):' + error.value)

    throw new FetchError('Could not fetch page (initial)', 500, error.value)
  }

  if (!data.value || !data.value.entry) {
    console.error('Could not fetch page (empty)')

    throw new FetchError('Could not fetch page (empty)', 404)
  }

  /**
   * For use after initial mounting of the app. Here, we cannot rely on useAsyncData anymore
   * and have to call gqlInstance directly, similar to $fetch vs. useAsyncData.
   */
  const getData = async () => {
    const gqlInstance = useGql()
    const newData = await gqlInstance('Page', {
      uri: uri.value,
      site: locale.value,
      status: isInLivePreview.value
        ? '(draft|expired|scheduled|published)'
        : 'published',
    })
    if (!newData || !newData?.entry) {
      throw new FetchError('Could not fetch page', 404)
    }
    return newData
  }

  return {
    entry: computed<PageQuery['entry']>(() => data.value?.entry),
    refresh: async (newFetchRoute: MaybeRef<RouteLocationNormalizedLoaded>) => {
      fetchRoute.value = toValue(newFetchRoute)
      data.value = await getData()
    },
  }
}
