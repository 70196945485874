import { type ComputedRef, inject } from 'vue'

function _useUrlResolver() {
  const getUrlPrefix = () => {
    try {
      const urlPrefix = inject<ComputedRef<string>>(
        'urlPrefix',
        computed(() => '')
      )
      return urlPrefix.value
    } catch ($error) {
      return ''
    }
  }

  return {
    getUrlPrefix,
  }
}

export const useUrlResolver = createSharedComposable(_useUrlResolver)
