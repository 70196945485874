import type { InjectionKey } from 'vue'
import type { Schemas } from '#shopware'

export const ShopNavigationKey: InjectionKey<{
  data: Ref<Schemas['NavigationRouteResponse'] | null>
  flat: Ref<Schemas['Category'][]>
}> = Symbol('ShopNavigation')

export const useInjectableShopNavigation = async () => {
  const { locale } = useI18n()
  const { loadNavigationElements, navigationElements } = useNavigation()

  const { data, refresh } = await useAsyncData('useNavigation', async () => {
    await loadNavigationElements({ depth: 4 })
    return navigationElements.value
  })

  const flat = computed(() => flattenCategoryTree(data.value ?? []))

  const flattenCategoryTree = (tree: Schemas['Category'][]) =>
    tree.reduce(
      (flattened, category) => {
        flattened = flattened.concat(category)
        if (category.children) {
          flattened = flattened.concat(flattenCategoryTree(category.children))
        }
        return flattened
      },
      [] as Schemas['Category'][]
    )

  watch(locale, () => {
    refresh()
  })

  return {
    data,
    flat,
  }
}
