export type AccountCategory = {
  name: string
  link: string
  fn?: () => Promise<any>
}

export function useAccountCategories(isLoggedIn: Boolean) {
  const { getShopRoute } = useShopRouter()
  const { t } = useI18n()
  const { logout } = useUser()

  const arrayCategories = computed(() => {
    const items: AccountCategory[] = [
      {
        name: t('account.overview'),
        link: getShopRoute(`/account`),
      },
      {
        name: t('account.addresses'),
        link: getShopRoute(`/account/address`),
      },
      {
        name: t('account.orders'),
        link: getShopRoute(`/account/order`),
      },
      {
        name: t('account.profile'),
        link: getShopRoute(`/account/profile`),
      },
    ]

    if (isLoggedIn) {
      items.push({
        name: t('account.logout'),
        link: getShopRoute(`/`),
        fn: () => logout(),
      })
    } else {
      items.push({
        name: t('account.login'),
        link: getShopRoute(`/account/login`),
      })
    }

    return items
  })

  return { arrayCategories }
}
