import revive_payload_client_W0IAXtrpvE from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@8.57.1_ioredis@5._57faazw2peg5qyd5ovw3lflnhy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Ja7rIDpfUK from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@8.57.1_ioredis@5._57faazw2peg5qyd5ovw3lflnhy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CTdAwl0AAB from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@8.57.1_ioredis@5._57faazw2peg5qyd5ovw3lflnhy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_8sk65qKrJm from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@8.57.1_ioredis@5._57faazw2peg5qyd5ovw3lflnhy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_iIyaEs0MnQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@8.57.1_ioredis@5._57faazw2peg5qyd5ovw3lflnhy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_6IaRn5l7WO from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@8.57.1_ioredis@5._57faazw2peg5qyd5ovw3lflnhy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_SISo5FAGVi from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.33.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._ajwkgsuhdrqt6lc4zng3ix5hwm/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import animations_AqrJMKeSU3 from "/vercel/path0/node_modules/.pnpm/@teamnovu+kit-nuxt@1.1.4_magicast@0.3.5_nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.7.4__hxh77ls4yrfvvddkiacbewpfcu/node_modules/@teamnovu/kit-nuxt/src/runtime/animations.ts";
import plugin_liyViRA2ax from "/vercel/path0/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_tk4iDQOnTB from "/vercel/path0/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_graphql-t_aiykl65dcr5fka6hz4jm4ytcqe/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import i18n_hQHQPdakgF from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.5_rollup@4.24.0_vue@3.4.38_typescript@5.5.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_Hcm63LvLV4 from "/vercel/path0/node_modules/.pnpm/@shopware-pwa+nuxt3-module@1.0.5_magicast@0.3.5_rollup@4.24.0_vue@3.4.38_typescript@5.5.2__webpack-sources@3.2.3/node_modules/@shopware-pwa/nuxt3-module/plugin.ts";
import chunk_reload_client_s9k3jI4oEE from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.7.4_encoding@0.1.13_eslint@8.57.1_ioredis@5._57faazw2peg5qyd5ovw3lflnhy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import inlineUtils_X58FG1MpoS from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/inlineUtils.ts";
import livePreview_RrjgcDm40s from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/livePreview.ts";
import onError_lHlIdZiLD7 from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/onError.ts";
import vue_cloudinary_image_GGoxRgqUcV from "/vercel/path0/node_modules/.c12/github_teamnovu_nuxt_m1o5eah4I2/plugins/vue-cloudinary-image.ts";
import cookieconsent_hBUClLyRD3 from "/vercel/path0/plugins/cookieconsent.ts";
import veeValidate_U1l0qzVbBC from "/vercel/path0/plugins/veeValidate.ts";
export default [
  revive_payload_client_W0IAXtrpvE,
  unhead_Ja7rIDpfUK,
  router_CTdAwl0AAB,
  payload_client_8sk65qKrJm,
  check_outdated_build_client_iIyaEs0MnQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6IaRn5l7WO,
  sentry_client_SISo5FAGVi,
  sentry_client_config_o34nk2sJbg,
  animations_AqrJMKeSU3,
  plugin_liyViRA2ax,
  plugin_tk4iDQOnTB,
  i18n_hQHQPdakgF,
  plugin_Hcm63LvLV4,
  chunk_reload_client_s9k3jI4oEE,
  inlineUtils_X58FG1MpoS,
  livePreview_RrjgcDm40s,
  onError_lHlIdZiLD7,
  vue_cloudinary_image_GGoxRgqUcV,
  cookieconsent_hBUClLyRD3,
  veeValidate_U1l0qzVbBC
]