<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20">
    <g transform="translate(-2318 19481)">
      <path
        d="M19,9.5v9.441a.532.532,0,0,1-.5.559H2.5a.532.532,0,0,1-.5-.559V9.5"
        transform="translate(2318 -19481)"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        d="M13,19.5H8V15a2.5,2.5,0,0,1,5,0Z"
        transform="translate(2318 -19481)"
        fill="none"
        stroke="currentColor"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <g id="Gruppe_12645" data-name="Gruppe 12645">
        <line
          x2="20"
          transform="translate(2318.5 -19461.5)"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
      </g>
      <path
        d="M18.691,1.882A2.5,2.5,0,0,0,16.455.5H4.545A2.5,2.5,0,0,0,2.309,1.882l-1.2,2.4A5.753,5.753,0,0,0,.5,6.858,2.6,2.6,0,0,0,2.742,9.487,2.5,2.5,0,0,0,5.5,7a2.5,2.5,0,0,0,5,0,2.5,2.5,0,0,0,5,0,2.5,2.5,0,0,0,2.758,2.487A2.6,2.6,0,0,0,20.5,6.857a5.751,5.751,0,0,0-.607-2.571Z"
        transform="translate(2318 -19481)"
        fill="none"
        stroke="currentColor"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </g>
  </svg>
</template>
