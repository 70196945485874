import { computed } from 'vue'
import type {
  Breadcrumb,
  UseBreadcrumbsReturn,
} from '@shopware-pwa/composables-next'

export function useBreadcrumbs(
  newBreadcrumbs?: Breadcrumb[]
): UseBreadcrumbsReturn {
  const _breadcrumbs = useState<Breadcrumb[]>('breadcrumbs', () => [])

  if (newBreadcrumbs) {
    _breadcrumbs.value = newBreadcrumbs
  }

  const clearBreadcrumbs = () => {
    _breadcrumbs.value = []
  }

  return {
    clearBreadcrumbs,
    breadcrumbs: computed(() => _breadcrumbs.value),
  }
}
