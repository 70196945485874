<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.9390967694 21">
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M13.4699380317,7.5v-3c0-2.2091391087-1.7908611298-4-4-4s-4,1.7908608913-4,4v3"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M15.4389777602,20.5H3.5001190092c-1.730279082,0-3.101849434-1.4597416481-2.9941868802-3.1866679569l.6259392662-10.0401759875c.0985837189-1.5813002002,1.4098166354-2.8133320431,2.9941868803-2.8133320431h10.6869802186c1.5843702448,0,2.8956031614,1.2320318429,2.9941868802,2.8133320431l.6259392662,10.0401759875c.1076625537,1.7269263088-1.2639077982,3.1866679569-2.9941868802,3.1866679569Z"
    />
  </svg>
</template>
