import {
  createCookieConsent,
  CookieConsentConfig,
  useCookieConsent,
} from '@teamnovu/kit-cookieconsent'
import cookieConsentTranslations from './cookieconsent/language'

export default defineNuxtPlugin(({ vueApp }) => {
  const consentConfig: CookieConsentConfig = {
    guiOptions: {
      consentModal: {
        layout: 'cloud',
        position: 'bottom',
        flipButtons: false,
        equalWeightButtons: true,
      },
    },
    language: {
      default: vueApp.$nuxt.$i18n.locale.value,
      autoDetect: 'document',
      translations: cookieConsentTranslations,
    },
  }

  vueApp.use(createCookieConsent(consentConfig))

  if (process.client) {
    const cookieconsent = useCookieConsent()!
    // const gtm = useGtm()

    watch(
      cookieconsent.services,
      (services) => {
        // gtm?.enable(services.analytics ?? false)
      },
      { immediate: true }
    )
  }
})
