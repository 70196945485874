<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9">
    <g id="Group_9730" data-name="Group 9730" transform="translate(0.5 0.5)">
      <path
        d="M300-11533.456h8"
        transform="translate(-300 11537.456)"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-width="1"
      />
      <path
        d="M0,0H8"
        transform="translate(4) rotate(90)"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-width="1"
      />
    </g>
  </svg>
</template>
