<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 21.007 18.839"
  >
    <g transform="translate(-1171.496 -21.996)">
      <g transform="translate(1171.496 21.996)">
        <g transform="translate(-1171.496 -21.996)">
          <path
            d="M11.993,5.136A5.44,5.44,0,0,0,4.155,4.31a5.955,5.955,0,0,0-.89,8.25,93.01,93.01,0,0,0,8.208,7.98,1.152,1.152,0,0,0,.378.273.5.5,0,0,0,.286,0,1.152,1.152,0,0,0,.378-.273,93.012,93.012,0,0,0,8.208-7.98,5.913,5.913,0,0,0-.89-8.25A5.455,5.455,0,0,0,11.993,5.136Z"
            transform="translate(1170 19.5)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            fill-rule="evenodd"
            v-bind="$attrs"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
