<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
    <path
      d="M5.316,19.438A4,4,0,0,1,9,17h6a4,4,0,0,1,3.684,2.438M16,9.5a4,4,0,1,1-4-4A4,4,0,0,1,16,9.5ZM22,12A10,10,0,1,1,12,2,10,10,0,0,1,22,12Z"
      transform="translate(-1.5 -1.5)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
  </svg>
</template>
