import type { NavigationsAndGlobalsQuery } from '#gql'

export const GlobalsKey: InjectionKey<
  Ref<Enforce<NavigationsAndGlobalsQuery>>
> = Symbol('Globals')

export const useInjectableGlobals = async () => {
  const { locale } = useI18n()
  const { data, error } = await useAsyncGql({
    operation: 'NavigationsAndGlobals',
    variables: { site: locale },
  })

  if (error.value) {
    console.error('Unable to fetch static', toRaw(error))
    throw createError({
      statusCode: 500,
      fatal: true,
      message: error.value as unknown as string,
      statusMessage: "Can't load static data",
    })
  }

  return data as Ref<Enforce<NavigationsAndGlobalsQuery>>
}
