<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.207 20.207">
    <path
      d="M22,22l-4.592-4.592m2.481-5.964A8.444,8.444,0,1,1,11.444,3,8.444,8.444,0,0,1,19.889,11.444Z"
      transform="translate(-2.5 -2.5)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
  </svg>
</template>
