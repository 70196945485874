<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 21"
    width="20"
    height="20"
  >
    <path
      d="M16,6V5.2a4.968,4.968,0,0,0-.218-2.108,2,2,0,0,0-.874-.874A4.969,4.969,0,0,0,12.8,2H11.2a4.969,4.969,0,0,0-2.108.218,2,2,0,0,0-.874.874A4.969,4.969,0,0,0,8,5.2V6m2,5.5v5m4-5v5M3,6H21M19,6V17.2a7.453,7.453,0,0,1-.327,3.162,3,3,0,0,1-1.311,1.311A7.453,7.453,0,0,1,14.2,22H9.8a7.453,7.453,0,0,1-3.162-.327,3,3,0,0,1-1.311-1.311A7.453,7.453,0,0,1,5,17.2V6"
      transform="translate(-2.5 -1.5)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
  </svg>
</template>

<script setup lang="ts"></script>
