<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 11">
    <g transform="translate(-566.609 -107.5)">
      <path
        d="M-20746.389,130h14"
        transform="translate(21312.998 -22)"
        fill="none"
        stroke="currentColor"
        stroke-width="1"
      />
      <path
        d="M-20746.389,130h14"
        transform="translate(21312.998 -17)"
        fill="none"
        stroke="currentColor"
        stroke-width="1"
      />
      <path
        d="M-20746.389,130h14"
        transform="translate(21312.998 -12)"
        fill="none"
        stroke="currentColor"
        stroke-width="1"
      />
    </g>
  </svg>
</template>
